<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.users')">

            <CustomTable  ref="userTable" class="mt-1" :fields="fields" api-url="/api/client/v1/users/">
                <template #buttons v-if="$hasPermission($permissions.UserConfigurationWrite)">
                    <b-button variant="primary" @click="$refs.addModal.open()">
                        <feather-icon icon="PlusSquareIcon"/>
                        {{ $t('config.users.add_user') }}
                    </b-button>
                </template>
                <template #status="{item}">
                    <div v-for="item2 in invitationStatuses" :key="item2.id">
                        <b-badge :variant="item2.variant" class="w-100" v-if="item2.id === item.status">
                            {{item2.label}}
                        </b-badge>
                    </div>
                </template>
                <template #actions="{item}">
                    <div class="d-flex justify-content-center">
                        <template v-if="item.status ===InvitationStatus.Confirmed">
                            <b-button class="mr-1 m-0 button" variant="danger" size="sm" @click="remove(item)">
                                {{ $t('general.delete') }}
                            </b-button>
                            <b-button class="mr-1 m-0 button" variant="warning" size="sm" @click="editRow(item)">
                                {{ $t('general.edit') }}
                            </b-button>
                        </template>
                        <template v-else-if="item.status ===InvitationStatus.Expired">
                            <b-button class="mr-1 m-0 button" variant="danger" size="sm" @click="deleteInvitation(item.email)">
                                {{ $t('general.delete') }}
                            </b-button>
                            <b-button class="mr-1 m-0 button" variant="warning" size="sm" @click="resend(item)">
                                {{ $t('mail.resend') }}
                            </b-button>
                        </template>
                    </div>
                </template>
            </CustomTable>
        </b-card>

        <Adduser ref="addModal" v-on:itemAdded="loadData"/>
    </b-overlay>
</template>

<script>
    import Adduser from '@/views/User/AddUser'
    import {BButton, BCard, BOverlay, BBadge} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import CustomTable from '@/components/CustomTable.vue'
    import {InvitationStatus} from '@/libs/enums/InvitationStatus'

    export default {
        components: {CustomTable, Adduser, BCard, BOverlay, BButton, BBadge},
        computed: {
            invitationStatuses()  {
                return [
                    {
                        label: this.$t('mail.statuses.confirmed'),
                        id: InvitationStatus.Confirmed,
                        variant:'success'
                    },
                    {
                        label:  this.$t('mail.statuses.in_progress'),
                        id: InvitationStatus.ConfirmationInProgress,
                        variant:'info'
                    },
                    {
                        label:  this.$t('mail.statuses.expired'),
                        id: InvitationStatus.Expired,
                        variant:'warning'
                    }
                ]
            },
            InvitationStatus() {
                return InvitationStatus
            },
            userData() {
                return this.$store.getters['user/userData']
            },
            fields() {
                const hasPermission =  this.$hasPermission(this.$permissions.UserConfigurationWrite)
                const defs = [
                    {
                        key: 'name', // kaj kaze v tabeli
                        label: this.$t('config.users.user'),
                        filter: true,
                        filterType: 'input',
                        filterKey: 'name.w',
                        permissions: true,
                        minWidth: 140
                    },
                    {
                        key: 'email',
                        label: this.$t('form_data.email'),
                        filter: true,
                        filterType: 'input',
                        filterKey: 'email.w',
                        permissions: true,
                        minWidth: 200
                    },
                    {
                        key: 'status',
                        label: this.$t('mail.status'),
                        filter: false,
                        permissions: true,
                        minWidth: 200
                    }

                ]

                if (hasPermission) {
                    defs.push(
                        {
                            key: 'actions',
                            label: this.$t('table_fields.actions'),
                            permissions: true
                        }
                    )
                }

                return defs
            }
        },
        data() {
            return {
                users: [],
                showLoader: false
            }
        },
        methods: {
            loadData() {
                this.$refs.userTable.loadData()
            },
            async resend(user) {
                try {
                    this.loading = true
                    await this.$http.post('/api/client/v1/users/resend', user)
                    this.$printSuccess(this.$t('print.message.resent_email'))
                    await this.loadData()
                } catch (err) {
                    this.$printError(err)
                } finally {
                    this.loading = false
                }
            },
            async deleteInvitation(email) {
                try {
                    const confirmDelete = await this.$confirmDialog('Ali res želite izbrisati vrstico?')
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/users/invitation/${email}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.loadData()
                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)

                }
            },
            async remove(data) {
                try {
                    if (this.userData.id === data.id) {
                        this.$printError(this.$t('print.error.on_delete_current_user'))
                        return
                    }

                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/users/${data.id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    this.$refs.userTable.loadData()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            editRow(data) {
                this.$refs.addModal.open(data)
            }
        }

    }
</script>

<style scoped>

</style>
